import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RichText from '~components/RichText';
import Container from '~components/Container';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& > .MuiExpansionPanel-root::before': {
      height: '0px'
    }
  },
  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0  auto'
  },
  expansionPanel: {
    display: 'block',
    padding: '0px'
  },
  expansionSummarySpacing: {
    margin: '0px 0px',
    padding: '0px 0px'
  },
  buttonStyle: props => ({
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    padding: '15px 52px',
    margin: '0 auto',
    marginTop: '50px',
    boxShadow: 'unset',
    backgroundColor: props.button_bg_color || '#458FCE',
    color: props.button_title_color || '#fff',
    '&:hover': {
      boxShadow: 'unset',
      backgroundColor: props.button_bg_color || '#458FCE',
      opacity: '0.8'
    }
  }),
  containerSpacing: {
    marginBottom: '100px',
    marginTop: '100px'
  },
  expansionPanelStyle: {
    boxShadow: 'unset',
    border: 'unset',
    padding: '20px 25px 20px 32px',
    borderRadius: '4px',
    marginBottom: '14px'
  }
}));

const AboutUs = ({ about_us_bg_color, section_title, sections }) => {
  const classes = useStyles();
  const defaultExpandedPanel =
    sections && sections[0] && sections[0].topic_title && sections[0].topic_title.html;
  const defaultImageData = sections && sections[0] && sections[0].topic_image;
  const [expanded, setExpanded] = useState(defaultExpandedPanel || '');
  const [sectionImage, setSectionImage] = useState(defaultImageData || {});

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    const currentSectionImage = sections.find(
      section => section.topic_title && section.topic_title.html === panel
    );
    setSectionImage(currentSectionImage.topic_image);
  };

  return (
    <Container background={about_us_bg_color}>
      <Box className={classes.containerSpacing}>
        <RichText
          html={section_title && section_title.html}
          mode="teamPageJobSectionTitle"
          verticalSpacing={0}
        />
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12} md={7}>
            <div className={classes.root}>
              {sections &&
                sections.map(section => {
                  const title = section && section.topic_title && section.topic_title.html;
                  return (
                    <Accordion
                      expanded={expanded === title}
                      onChange={handleChange(title)}
                      className={classes.expansionPanelStyle}
                      style={{
                        backgroundColor: expanded === title ? '#fff' : 'rgba(255, 255, 255, 0.3)'
                      }}
                      key={title}
                    >
                      <AccordionSummary
                        aria-controls={title}
                        id={title}
                        className={classes.expansionSummarySpacing}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <RichText html={title} mode="aboutFaqTitle" verticalSpacing={0} />
                      </AccordionSummary>
                      <AccordionDetails className={classes.expansionPanel}>
                        <RichText
                          html={section && section.topic_content && section.topic_content.html}
                          mode="faqTopicContent"
                          verticalSpacing={0}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <img
              src={sectionImage && sectionImage.url}
              alt={sectionImage && sectionImage.alt}
              className={classes.media}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

AboutUs.propTypes = {
  section_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      topic_title: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired,
      topic_content: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired,
      topic_image: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  about_us_bg_color: PropTypes.string.isRequired
};

export default AboutUs;
